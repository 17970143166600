
import { loadBlockData } from '~/components/globals/blocks/list'
import getCraftPages from '~/queries/craft-pages.gql'
import getVideos from "~/queries/get-video.gql"
import pageMixin from '@bkwld/cloak/mixins/page'
import { isDark } from '@bkwld/light-or-dark'

export default
	name: 'Tower'

	mixins: [ pageMixin ]

	# Provide page hash needed for cueing up featured video in video-carousel component
	provide: -> { @pageHash }

	# Get Tower data
	asyncData: ({ app, store, params, $axios, route, query }) ->

		# Don't query for these paths
		return app.$notFound() if params.tower in [ '__webpack_hmr' ]

		# Get data
		[ page ] = await app.$craft.getEntries
			query: getCraftPages
			variables:
				section: 'towers'
				type: 'towers'
				uri: params.tower || '__home__'
		return app.$notFound() unless page

		# Set data
		store.commit 'notification-bar/setData', page.notificationBar?[0]
		store.commit 'tower/setLightMarquee', do ->
			return true if params.tower in ['404', '500'] # Error pages are light
			return false unless topBlock = page.blocks[0]
			if page.pushDown then return true
			switch topBlock.__typename
				when 'blocks_simpleMarquee_BlockType' then true
				when 'blocks_spacer_BlockType' then true
				when 'blocks_wrapper_BlockType' then !isDark topBlock.backgroundColor
				when 'blocks_standardMarquee_BlockType' then isDark topBlock.textColor
				else false

		store.commit 'tower/setDarkLastBlock', do ->
			return if params.tower in ['404', '500']
			lastBlock = page.blocks[page.blocks.length - 1]
			if !lastBlock then return
			switch lastBlock.__typename
				when 'blocks_wrapper_BlockType' then isDark lastBlock.backgroundColor
				else false

		pageHash = route.hash

		videoId = query.internalVideo

		if videoId
			# Get video data
			data = await app.$craft.getEntries
				query: getVideos
				variables:
					id: [ videoId ]

		return { page, pageHash, video: data?[0] }

	computed: classes: -> ['push-down' if @page.pushDown]

	mounted: ->
		if @video
			mp4Video = @video
			@$store.commit 'videoModal/play', {video: mp4Video.videoDesktop?[0] || mp4Video.videoMobile?[0], title: mp4Video.title, subtitles: mp4Video.subtitles }



